.b-form-tags {
  .b-form-tags-list {
    margin-top: -0.25rem;

    .b-form-tag {
      margin-top: 0.25rem;
    }
  }

  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }

    &.is-valid {
      border-color: $form-feedback-valid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-color, 0.25);
    }

    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
    }
  }

  &.disabled {
    background-color: $input-disabled-bg;
  }
}

.b-form-tag {
  // Override default badge settings
  // Due to using text-truncate on the inner content
  font-size: 75%;
  font-weight: normal;
  line-height: $input-line-height;
  margin-right: 0.25rem;

  &.disabled {
    opacity: 0.75;
  }

  // Override default close button settings
  > button.b-form-tag-remove {
    color: inherit;
    font-size: 125%;
    line-height: 1;
    float: none;
    margin-left: 0.25rem;
  }
}

.form-control-sm .b-form-tag {
  line-height: $input-line-height-sm;
}

.form-control-lg .b-form-tag {
  line-height: $input-line-height-lg;
}
